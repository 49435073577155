footer {
  // background-color: blue;
  position: fixed;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  gap: 0.5em;
  z-index: 5;
  a.footer-link {
    display: block;
    position: relative;
    width: 1.5em;
    height: 1.5em;
    text-decoration: none;
    color: var(--button-primary-color);
    opacity: 0.4;
    transition: opacity 0.3s;

    .icon {
      width: 100%;
      height: 100%;
      background: url('../assets/icons/github.svg') center no-repeat;
      background-size: contain;
      &.portfolio {
        background-image: url('../assets/icons/portfolio.svg');
      }
      &.rss {
        background-image: url('../assets/icons/rss-logo.svg');
      }
    }

    .hint {
      position: absolute;
      width: max-content;
      padding-right: 1em;
      padding: 0 0.8em 0 0.5em;
      align-items: center;
      top: 0;
      bottom: 0;
      right: 100%;
      display: none;
      &::before {
        content: '';
        z-index: -1;
        position: absolute;
        inset: 0 0.3em 0 0;
        border-radius: 0.3em;
        background-color: var(--button-primary-bg-color);
      }
    }
    &:hover {
      opacity: 1;
      & .hint {
        // opacity: 0;
        display: flex;
        animation: hint-animation 0.5s ease-out;
      }
    }    
  }
}

@keyframes hint-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.dark-theme footer a.footer-link .icon {
  filter: invert(1);
}